import React from "react";
import Navigation from "../components/Navigation";
import BottomNav from "../components/BottomNav";
import Mouse from "../components/Mouse";
import SocialNetwork from "../components/SocialNetwork";
import Logo from "../components/Logo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContactForm from "../components/ContactForm";
import { motion } from 'framer-motion';


const Contact = () => {

  const variants = {
    in: {
      opacity:1,
      x: 0
    },
    out: {
      opacity :0,
      x: 300
    }
  }

  const transition = {
    ease : [.03,.87,.73,.9],
    duration: .6,
  }

  return (
    <main>
      <Mouse />
      <motion.div className="contact"
      exit="out"
      animate="in"
      initial="out"
      variants={variants}
      transition={transition}>
        <Navigation />
        <Logo />
        <ContactForm />
        <div className="contact-infos">
          <div className="address">
            <div className="content">
              <h4>adresse</h4>
              <p>12 rue du Code</p>
              <p>42000 Saint Etienne</p>
            </div>
          </div>
          <div className="phone">
            <div className="content">
              <h4>téléphone</h4>
              <CopyToClipboard text="0623148965" className="hover">
                <p
                  style={{ cursor: "pointer" }}
                  className="clipboard"
                  onClick={() => {
                    alert("Téléphone copié !");
                  }}
                >
                  06 23 14 89 65
                </p>
              </CopyToClipboard>
            </div>
          </div>
          <div className="email">
            <div className="content">
              <h4>email</h4>
              <CopyToClipboard text="allan.chene@allan.fr" className="hover">
                <p
                  style={{ cursor: "pointer" }}
                  className="clipboard"
                  onClick={() => {
                    alert("email copié !");
                  }}
                >
                  allan.chene@allan.fr
                </p>
              </CopyToClipboard>
            </div>
          </div>
          <SocialNetwork />
          <div className="credits">
            <p>Allan Chêne 2021</p>
          </div>
        </div>
        <BottomNav left={"project-4"} />
      </motion.div>
    </main>
  );
};

export default Contact;
